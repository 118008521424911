/**
 * Basic properties of the application as they are presented on the user
 * interface.
 */
export const appDescriptor = {
    name: "Aker",
};

/**
 * Whether the app is being built in production mode. This is set by
 * Webpack at bundling time. In the absence of a Webpack bundler, this
 * will evaluate to `false`.
 */
export const isInProductionMode = process.env.NODE_ENV === "production";

/**
 * The default locale for Numbro and other locale-aware libraries that we
 * might use.
 */
export const defaultLocale = "en-US";

/**
 * Whether API calls should be made only if we are currently authenticated
 * to the API.
 */
export const loginRequired = true;

/**
 * Waiting time between upload status checks.
 */
export const waitingTimeInMilisecondsBeforeUploadStatusCheck = 1000;

/**
 * Max number of iterations in file upload status check.
 */
export const defaultMaxNumberOfIterationsForFileUploadStatusCheck = 5;

/**
 * Wheter the perspective settings menu button is enabled.
 */
export const isPerspectiveSettingsMenuButtonEnabled = true;

/**
 * Constant for workbench in local storage.
 */
export const localStorageConstantForWorkbench = "workbench";

/**
 * Constant for drawers state in local storage.
 */
export const localStorageConstantForDrawers = "drawers";

/**
 * Constant for sidebar state in local storage.
 */
export const localStorageConstantForSidebar = "sidebar";

/**
 * Constant for perspectives state in local storage.
 */
export const localStorageConstantForPerspectives = "perspectives";

/**
 * Constant for selected perspective id in local storage.
 */
export const localStorageConstantForSelectedPerspectiveId = "selectedPerspectiveId";

/**
 * Constant for version of the stored data in local storage.
 */
export const localStorageConstantForLocalStorageVersion = "version";

/**
 * Version number for the stored data in the local storage.
 */
export const localStorageVersion = "v1.1";

/**
 * WebSocket URL
 */
export const WEBSOCKET_URL: string | undefined = process.env.WEBSOCKET_URL;

/**
 * Socket.IO path (optional)
 */
export const SOCKETIO_PATH: string | undefined = process.env.SOCKETIO_PATH;

/**
 * Filter the perspectives by permissions.
 */
export const ENABLE_PERSPECTIVE_FILTERING_BY_PERMISSIONS = false;

export enum ProductionTypes {
    Cutting = "cutting",
    Prefab = "production",
    PreAssembly = "pre_assembly",
    Assembly = "assembly",
    Purchasing = "purchasing",
    Milestone = "milestone",
    Other = "other",
}

export const productionTypesLabels: Record<ProductionTypes, string> = {
    [ProductionTypes.Cutting]: "cutting",
    [ProductionTypes.Prefab]: "prefab",
    [ProductionTypes.PreAssembly]: "pre-assembly",
    [ProductionTypes.Assembly]: "assembly",
    [ProductionTypes.Purchasing]: "purchasing",
    [ProductionTypes.Milestone]: "milestone",
    [ProductionTypes.Other]: "other",
};

export const selectedProjectLocalStorageKey = "selectedProjectState";

export const defaultStationScheduleRowHeight = 60;

export const masterTableId = "mt";

export const projectOverviewTableId = "po";

export const globalDateFormatKey = "<DEFAULT>";

export const defaultDateFormats = [
    globalDateFormatKey,
    "yyyy-MM-dd",
    "yyyy-MM-dd HH:mm",
    "dd.MM.yyyy",
    "dd.MM.yyyy HH:mm",
    "dd MMM yy",
    "dd MMM yy HH:mm",
    "'W'WW dd.MM.yy",
    "'W'WW dd.MM.yy HH:mm",
    "'W'WW yyyy",
];

export const defaultEditorDateFormats = ["yyyy-MM-dd", "dd.MM.yyyy", "dd MMM yy"];

export const workbenchStateChangeEvent = "wbStateChange";
